export const adminroutes = [
    {
		component: "apps/qifayi/service/chart.vue",
		guard_name: "api",
		icon: "yonghu",
		layout: "1",
		name: "会话",
		path: "/chart",
		props: null,
		router_name: "chart",
		vue_name: "chart",
		app_level:0,
		_child: [],	
	}
]